import defu from 'defu'
import options from './options'
import { Echo } from '~echo'

/**
 * @type {import('@nuxt/types').Plugin}
 */
export default async function (ctx, inject) {
  const echoOptions = typeof options === 'function' ? await options(ctx) : options

  const echo = new Echo(ctx, defu(echoOptions, {"broadcaster":"null","encrypted":false,"authModule":false,"connectOnLogin":true,"disconnectOnLogout":true,"disableStats":true,"wsHost":"staging.loverly.com","wsPort":443,"wssPort":443,"authEndpoint":"https:\u002F\u002Fstaging.loverly.com\u002Fapi\u002Fv1\u002Fbroadcasting\u002Fauth","enabledTransports":["ws","wss"],"forceTLS":false,"key":"Events"}))
  await echo.init()

  inject('echo', echo)
  ctx.$echo = echo
}
