import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            userInfos: (state) => state.data.userInfos,
            userData: (state) => state.auth.user || {},
        }),
        hasContact() {
            return !!this.userInfos.contact_count
        },
    },

    methods: {
        navigateToTool(tool) {
            if (tool === 'guests') {
                this.$router.push({ name: 'tools-guest-list' })
            } else if (tool === 'checklist') {
                this.$router.push({ name: 'tools-wedding-checklist' })
            } else if (tool === 'collections') {
                this.$router.push({ name: 'users-username-slug', params: { slug: 'boards', username: this.userData.username } })
            }
        },
    },

    beforeMount() {
        if (this.userData?.username) this.$store.dispatch('data/setUserInfos')
    },

    mounted() {
        this.$cookies.set('tool', null)
    },
}
