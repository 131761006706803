import Vue from 'vue'
import { parseISO, format } from 'date-fns'

Vue.filter('toDate', function (value, formatTemplate = 'MM-dd-yyyy') {
    if (!value) return 'Missing date'
    return format(parseISO(value), formatTemplate)
})

Vue.filter('toTime', function (value) {
    if (!value) return 'Missing date'
    try {
        return format(parseISO(value), 'h:mm a')
    } catch (e) { return value }
})


Vue.filter('toCurrency', function (value) {
    if (!value) return 'Missing amount'
    return `$${value.toLocaleString()}`
})

Vue.filter('toDateTime', function (value) {
    if (!value) return 'Missing date'
    try {
        return format(parseISO(value), 'MM-dd-yyyy h:m a')
    } catch (e) { return value }
})
