
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
    props: {
        dashboard: Boolean,
    },
    data() {
        return {
            open: false,
            menuOpen: undefined,
            subMenuOpen: undefined,
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
            isImpersonating: (state) => state.user.isImpersonating,
        }),

        ...mapGetters({ breakHeader: 'data/breakHeader' }),

        isVendor() {
            return !!this.user?.is_vendor
        },
        menu() {
            return this.globalTexts?.megaMenu || {}
        },
        masterclassEnabled() {
            return this.$config.masterclassEnabled
        },
        hideHamburger(){
            return !this.$auth.loggedIn && !this.breakHeader
        },
        dashboardMenu() {
            return this.isVendor
                ? [
                      { name: 'Home', icon: 'home', to: { name: 'index' } },
                      { name: 'Dashboard', icon: 'sliders', to: { name: 'vendor-dashboard' } },
                      { name: 'My Tasks', icon: 'check-circle', to: { name: 'vendor-checklist' } },
                      { name: 'Profile', icon: 'users', to: { name: 'vendor-management-step', params: { step: 1 } } },
                      { name: 'Submissions', icon: 'paper-plane', to: { name: 'tools-vendor-submit-wedding-my-weddings' } },
                  ]
                : [
                      { name: 'Home', icon: 'home', to: { name: 'index' } },
                      { name: 'Dashboard', icon: 'sliders', to: { name: 'dashboard' } },
                      { name: 'Checklist', icon: 'check-circle', to: { name: 'tools-wedding-checklist' } },
                      { name: 'Guest List', icon: 'users', to: { name: 'tools-guest-list' } },
                      this.masterclassEnabled ? { name: 'Videos & Guides', icon: 'video', to: { name: 'tools-videos' } } : undefined,
                      { name: 'Vendor Manager', icon: 'briefcase', to: { name: 'tools-vendor-manager' } },
                      { name: 'My Submissions', icon: 'rings-wedding', to: { name: 'tools-submit-wedding-my-weddings' } },
                      {
                          name: 'Wish List',
                          icon: 'shopping-bag',
                          to: { name: 'users-username-slug', params: { username: this.user?.username, slug: 'products' } },
                      },
                      { name: 'Mood Boards', icon: 'heart', to: { name: 'users-username-boards', params: { username: this.user?.username } } },
                  ]
        },
        navigationContentClass() {
            return this.breakHeader
                ? 'fixed flex flex-col top-0 left-0 h-screen max-w-xs w-full overflow-auto scrollbar px-2 z-30'
                : 'absolute right-0 grid p-2 gap-1 rounded-md top-full min-w-44 drop-shadow-md mt-2'
        },
    },
    methods: {
        ...mapMutations({
            setProduct: 'products/setItem',
        }),
        openSubmenu(menuItem) {
            this.menuOpen = this.menuOpen === menuItem.slug ? undefined : menuItem.slug
        },
        openSubMenuLinks(subMenuItem, index) {
            if (subMenuItem.links) this.subMenuOpen = this.subMenuOpen === `${subMenuItem.link}-${index}` ? undefined : `${subMenuItem.link}-${index}`
            else this.$router.push(subMenuItem.link)
        },
        toggleMenu() {
            this.open = !this.open
        },
        openMenu() {
            this.open = true
        },
        closeMenu() {
            if (this.open) {
                this.open = false
            }
        },
        logout() {
            this.setProduct()
            this.$auth.logout()
            this.$trackConnect()
        },
        async stopImpersonate() {
            const path = this.$cookies.get('impersonate_path') || '/'
            try {
                await this.$store.dispatch('user/stopImpersonate')
            } catch (q) {}
            if (path === this.$route.path) window.location.reload()
            else this.$router.replace(path)
        },
    },
}
