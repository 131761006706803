
export default {
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },

        value: {
            type: String,
            default: undefined,
        },
        center: Boolean
    },
    data() {
        return {
            tabKey: null,
            width: null,
            left: null,
            padding: 12,
        }
    },
    computed: {
        activeTab: {
            get() {
                return this.tabKey
            },
            set(value) {
                const el = this.$refs[value]?.[0]?.$el || this.$refs[value]?.[0]
                if (el) {
                    this.$emit('input', value)
                    this.tabKey = value
                    const client = el.getBoundingClientRect()
                    this.width = client.width - this.padding * 2
                    this.left = client.left - el.parentNode.getBoundingClientRect().left + this.padding
                }
            },
        },
    },
    watch: {
        value: {
            handler(key) {
                if (key) this.selectTab(key)
            },
        },
    },
    mounted() {
        if(this.tabs[0].to) {
            const index = this.tabs.findIndex(tab => tab.to.name === this.$route.name)
            if(index > -1) this.selectTab(this.tabs[index].key)
        } else this.selectTab(this.value ? this.value : this.tabs[0].key)
    },
    methods: {
        selectTab(key) {
            const index = this.tabs.findIndex((tab) => tab.key === key)
            if (index > -1 && this.activeTab !== this.tabs[index].key) {
                this.activeTab = this.tabs[index].key
            }
        }
    },
}
