export const eventActions = {
    'event-created'({ commit }, { model, route }) {
        if (model.id) commit('STORE_BACKUP', model)
    },
    async 'event-get'({ dispatch }, id) {
        const result = await dispatch('user/getEvents', undefined, { root: true })
        return result?.data?.find((event) => event.id === parseInt(id))
    },
    async 'event-save'({ dispatch, rootState }, model) {
        if (!model.taxonomy_event_id || !model.name) return
        try {
            const result = await this.app.$axios.$post('/api/v1/guest_wedding_events', model)
            if (result?.data) {
                if (!result.data.taxonomy_event_id) {
                    this.app.$toast.error('Error during creating event!').goAway(3000)
                    return
                }
                if (model.checklist && result.data.id)
                    await Promise.allSettled(
                        model.checklist.map((task) =>
                            this.$axios.$post('/api/v1/user-checklist-items', { ...task, ...{ guest_wedding_event_id: result.data.id } })
                        )
                    )

                const user = JSON.parse(JSON.stringify(rootState.auth.user))
                if (!user.wedding_details.events) user.wedding_details.events = []
                const idx = user.wedding_details.events?.indexOf(result.data.taxonomy_event_id)
                if (idx !== -1) {
                    this.app.$toast.error('Event already exists!').goAway(3000)
                    return
                }
                user.wedding_details.events.push(result.data.taxonomy_event_id)
                dispatch('user/update', user, { root: true })
            } else this.app.$toast.error('Duplicate event').goAway(3000)
        } catch (e) {
            this.app.$toast.error('Error during creating event!').goAway(3000)
            throw e
        }
    },
    async 'event-edit'({ dispatch, state, rootState }, model) {
        if (!model.taxonomy_event_id || !model.name) return
        const result = await this.app.$axios.$put(`/api/v1/guest_wedding_events/${model.id}`, model)
        if (result.data.taxonomy_event_id) {
            const user = JSON.parse(JSON.stringify(rootState.auth.user))
            if (result.data.taxonomy_event_id !== state.backup.taxonomy_event_id) {
                const index = user.wedding_details.events.indexOf(state.backup.taxonomy_event_id)
                user.wedding_details.events.splice(index, 1)
            }
            if (!user.wedding_details.events.includes(result.data.taxonomy_event_id)) {
                user.wedding_details.events.push(result.data.taxonomy_event_id)
                dispatch('user/update', user, { root: true })
            }
        }
    },
}
