export default {
    props: {
        defaultOpen: Boolean,
        sticky: Boolean,
        disabled: Boolean,
    },

    data() {
        return {
            isOpen: this.defaultOpen,
            watchRoute: true,
        }
    },

    mounted() {
        if (this.watchRoute) {
            this.$watch('$route', () => {
                if (!this.sticky) this.isOpen = false
            })
        }
    },

    methods: {
        open() {
            if(!this.disabled) this.isOpen = true
        },
        close() {
            if(!this.disabled) this.isOpen = false
        },
        toggle() {
            if(!this.disabled) this.isOpen = !this.isOpen
        },
        clickOutside(e) {
            if (e.target?.attributes?.for?.value !== this.$attrs['aria-ref'] || !this.$attrs['aria-ref']) this.close()
        },
    },
}
