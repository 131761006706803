
import { mapState, mapGetters, mapMutations } from 'vuex'
import openable from '~/mixins/openable'

export default {
    mixins: [openable],

    props: {
        desktopScroll: {
            type: Boolean,
            default: true,
        },
        dashboard: Boolean,
    },

    data() {
        return {
            mobile: undefined,
            modalShow: false,
            isSearch: false,
            productModalShow: false,
            product: null,
            isLogin: true,
            scrolled: false,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),

        ...mapGetters({ isPremiumUser: 'user/isPremiumUser', breakHeader: 'data/breakHeader', lgDevice: 'data/lgDevice', menu: 'layout/menu' }),

        currentPage() {
            return this.$route.name
        },
        isVendor() {
            return !!this.user?.is_vendor
        },
    },

    watch: {
        $route() {
            this.modalShow = false
        },

        $auth: {
            handler(val) {
                if (val.loggedIn) this.modalShow = false
            },
        },
    },

    mounted() {
        this.$store.subscribe((m) => {
            if (m && m.type === 'board/triggerBoardModal') {
                if (!this.$auth.loggedIn) {
                    this.modalShow = true
                }
            }
        })

        this.$store.subscribe((m) => {
            if (m && m.type === 'products/triggerProductModal') {
                this.product = m.payload
                this.setProduct(m.payload)
                if (this.product) {
                    if (!this.$auth.loggedIn) {
                        this.isLogin = false
                        this.modalShow = true
                    }
                    this.productModalShow = true
                }
            }
        })

        this.mobile = process.browser ? window.innerWidth < 992 : null

        if (this.$route.query.login) {
            this.modalShow = true
        }

        window.addEventListener('scroll', this.handleOffsetTopChange)
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleOffsetTopChange)
    },

    methods: {
        ...mapMutations({
            setProduct: 'products/setItem',
        }),

        handleOffsetTopChange() {
            this.scrolled = document.documentElement.scrollTop > 0
        },
        closeMegaMenuItem() {
            this.$refs.megaMenu?.closeMegaMenuItem(true)
        },
        // resetSetup() {
        //     const user = JSON.parse(JSON.stringify(this.user))
        //     // this.$set(user.wedding_details, 'events', [])
        //     this.$set(user, 'guestlist_setup_at', null)
        //     // this.$set(user, 'saved_ideas_setup_at', null)
        //     // this.$set(user.style_quiz_at, 'events', null)
        //     // this.$set(user.first_steps_at, 'events', null)
        //     this.$store.dispatch('user/update', user)
        //     // this.events.forEach((event) => {
        //     //     // const id = this.events.find((ev) => ev.taxonomy_event_id === event.id)?.id

        //     //     this.$axios.$delete('/api/v1/guest_wedding_events/' + event.id)
        //     // })
        //     // this.$store.dispatch('complete/event-save')
        // },
    },
}
