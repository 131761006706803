
import siteMixin from '@/mixins/siteMixin'
import { mapGetters } from 'vuex'

export default {
    name: 'VendorLayout',

    mixins: [siteMixin],

    computed: {
        ...mapGetters({
            backgroundImage: 'layout/backgroundImage',
            background: 'layout/background',
        }),
    },
}
