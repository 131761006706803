import download from 'js-file-download'

export const state = () => ({
    events: [],
    guests: [],
    guest: null,
    meta: {},
    counts: {},
    loading: false,
    messageModal: false,
    shareModal: false,
})

export const mutations = {
    SET_EVENTS(state, data) {
        state.events = data
    },
    SET_GUESTS(state, data) {
        state.guests = data
    },
    SET_GUEST(state, data) {
        state.guest = data
    },
    SET_META(state, data) {
        state.meta = data
    },
    SET_COUNTS(state, data) {
        state.counts = data
    },
    SET_LOADING(state, data) {
        state.loading = data
    },
    UPDATE_GUESTS(state, data) {
        if (data.id) {
            const index = state.guests.findIndex((guest) => guest.id === data.id)
            if (index > -1) state.guests[index] = data
        }
    },
    TOGGLE_MESSAGE(state,) {
       state.messageModal = !state.messageModal
    },
    TOGGLE_SHARE(state,) {
       state.shareModal = !state.shareModal
    },
}

export const actions = {
    async get({ commit, state }, { id }) {
        if (!id) throw new Error('Missing guest id!')
        commit('SET_LOADING', true)
        const response = await this.$axios.$get(`/api/v1/guests/${id}`)
        commit('SET_GUEST', response.data)
        return response
    },
    async getList({ commit, state }, { route, currentPage, perPage = 30 }) {
        commit('SET_LOADING', true)
        const response = await this.$axios.$get(`/api/v1/guests`, {
            params: {
                page: currentPage || route?.query?.page || 1,
                per_page: perPage || state.meta.per_page,
                guest_event_id: route?.query?.event,
                status: route?.query?.status,
                search: route?.query?.q,
                order_by: route?.query?.['order-by'] || undefined,
                order_dir: route?.query?.['order-dir'] || 'asc',
                missing_info: route?.query?.missing_info || undefined,
            },
        })
        commit('SET_EVENTS', response.guest_wedding_events)
        commit('SET_GUESTS', response.data)
        commit('SET_META', response.meta)
        commit('SET_COUNTS', Array.isArray(response.guest_wedding_event_counts) ? {} : response.guest_wedding_event_counts)
        commit('SET_LOADING', false)
        return response
    },
    async save({ dispatch, commit }, { model, isPublic = false, route, setStasuses = true }) {
        try {
            const apiUrl = isPublic ? '/api/v1/public/guests/' : '/api/v1/guests/'
            let result
            if (model.id) {
                const apiId = isPublic ? model.invite_id : model.id
                result = await this.$axios.$put(`${apiUrl}${apiId}`, model)
            } else {
                result = await this.$axios.$post(`${apiUrl}${isPublic ? route.params.username : ''}`, model)
            }
            commit('UPDATE_GUESTS', result.data)
            if (!setStasuses || !model.wedding_events?.length) return Promise.resolve(result.data)
            await Promise.allSettled(
                model.wedding_events.map((_, index) => dispatch('saveStatus', { guest: result.data, event: model.wedding_events[index], isPublic }))
            )
            return Promise.resolve(result.data)
        } catch (e) {
            Promise.reject(e)
        }
    },
    saveStatus(vuex, { guest, event, status, isPublic = false }) {
        const apiUrl = isPublic ? `/api/v1/public/guests/${guest.invite_id}` : `/api/v1/guests/${guest.id}`
        return this.$axios.$put(
            `${apiUrl}/guest_wedding_events/${event.id}/status`,
            status?.id
                ? { ...{ status: status.id }, ...{ invite_id: isPublic ? guest.invite_id : undefined } }
                : {
                      status: event.status || 0,
                      plus_one_count: event.plus_one_count,
                      skip_klaviyo: true,
                  }
        )
    },
    generateInviteId(vuex, model) {
        if (!model.id) throw new Error('Missing id!')
        return this.$axios.$post(`/api/v1/guests/${model.id}/generate-invite-id`)
    },
    delete(vuex, id) {
        return this.$axios.$delete(`/api/v1/guests/${id}`)
    },
    deleteBulk(vuex, ids) {
        return this.$axios.$post(`/api/v1/guests/destroy-many`, {ids: ids})
    },
    async export() {
        const result = await this.$axios.$get('/api/v1/guests/export', { responseType: 'blob' })
        download(result, `guests.xlsx`)
        return result
    },
    rsvp(vuex, { id }) {
        if (!id) throw new Error('Missing guest id!')
        return this.$axios.$post(`/api/v1/guests/${id}/rsvp`)
    },
    toggleMessage({ commit }) {
        commit('TOGGLE_MESSAGE')
    },
    toggleShare({ commit }) {
        commit('TOGGLE_SHARE')
    },
}
export const getters = {
    events: (state) => state.events,
    guests: (state) => state.guests,
    guest: (state) => state.guest,
    meta: (state) => state.meta,
    counts: (state) => state.counts,
    loading: (state) => state.loading,
    messageModal: (state) => state.messageModal,
    shareModal: (state) => state.shareModal,
}
