import { render, staticRenderFns } from "./MegaMenu.vue?vue&type=template&id=eda7c150&scoped=true&lang=pug"
import script from "./MegaMenu.vue?vue&type=script&lang=js"
export * from "./MegaMenu.vue?vue&type=script&lang=js"
import style0 from "./MegaMenu.vue?vue&type=style&index=0&id=eda7c150&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eda7c150",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyMegaMenuItem: require('/app/components/menus/megamenu/MegaMenuItem.vue').default,LoverlyMegaMenuNavItem: require('/app/components/menus/megamenu/MegaMenuNavItem.vue').default,LoverlyMobileMegaMenuItem: require('/app/components/menus/megamenu/MobileMegaMenuItem.vue').default})
