import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind.config'
const fullConfig = resolveConfig(tailwindConfig)

class Resolution {
    constructor(tailwindConfig, store) {
        const SM_BREAKPOINT_MAX = 'md-max'
        const HEADER_BREAKPOINT_MAX = 'md-max'
        this._isSmallDevice = false
        this._store = store
        this._tailwindConfig = tailwindConfig
        this._smBreakpointMax = this.getBreakpoint(SM_BREAKPOINT_MAX) || 991
        this._headerBreakpointMax = 1260
    }

    get smBreakpointMax() {
        return this._smBreakpointMax
    }

    get headerBreakpointMax() {
        return this._headerBreakpointMax
    }

    init = () => {
        this._store.dispatch('data/setDeviceSize', window.innerWidth <= this._smBreakpointMax)
        this._store.dispatch('data/setBreakHeader', window.innerWidth <= this._headerBreakpointMax)
    }

    getBreakpoint = (breakpointString) => {
        const breakpoint = this._tailwindConfig.theme.screens[breakpointString]?.max || this._tailwindConfig.theme.screens[breakpointString]
        if (typeof breakpoint === 'string') return parseInt(breakpoint.replaceAll('px', ''))
        return breakpoint
    }

    deviceSizeChange = (e) => {
        this._store.dispatch('data/setDeviceSize', e.matches)
    }

    breakHeaderChange = (e) => {
        this._store.dispatch('data/setBreakHeader', e.matches)
    }
}

export default function ({ store }) {
    const resolution = new Resolution(fullConfig, store)
    const deviceSize = window.matchMedia(`(max-width: ${resolution.smBreakpointMax}px)`)
    const breakHeader = window.matchMedia(`(max-width: ${resolution.headerBreakpointMax}px)`)

    deviceSize.addEventListener('change', resolution.deviceSizeChange)
    breakHeader.addEventListener('change', resolution.breakHeaderChange)
    resolution.init()
}
