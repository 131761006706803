import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=d5612508&lang=pug"
import script from "./Avatar.vue?vue&type=script&lang=js"
export * from "./Avatar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyV2Image: require('/app/components.v2/elements/Image.vue').default})
