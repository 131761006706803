
import VueMultiSelect from 'vue-multiselect'
import { mapState } from 'vuex'

export default {
    components: { VueMultiSelect },

    data() {
        return {
            search: null,
            value: null,
            hasSearch: false,
            searchTaxonomies: [
                'categories',
                'communities',
                'cultures',
                'moments',
                'religions',
                'seasons',
                'services',
                'settings',
                'styles',
                'venues',
                'colors',
                'wedding-type',
                'events',
            ],
            options: [],
        }
    },

    computed: {
        ...mapState({ taxonomies: (state) => state.data.taxonomies || [] }),

        allOptions() {
            return this.searchTaxonomies
                .reduce((acc, el) => {
                    if (el !== 'colors') acc = [...acc, ...this.taxonomies[el]]
                    else {
                        acc = [
                            ...acc,
                            ...this.taxonomies[el].reduce((acc, el) => {
                                acc.push(el)
                                if (el.children) el.children.forEach((x) => acc.push(x))
                                return acc
                            }, []),
                        ]
                    }
                    return acc
                }, [])
                .filter((el, index, self) => self.findIndex((selfEl) => selfEl.name === el.name) === index)
        },
    },

    watch: {
        $route: {
            handler() {
                this.hasSearch = !!this.$route.query.q
                this.search = this.$route.query.q || null
            },
            immediate: true,
        },
    },

    mounted() {
        if (process.browser) {
            const input = document.getElementsByClassName('multiselect__input')
            if (input.length) {
                input[0].focus()
                input[0].setAttribute('type', 'search')
                input[0].addEventListener('keydown', (e) => {
                    const key = e.which || e.keyCode
                    if (key === 13) {
                        this.$refs.globalSearch.toggle()

                        this.$nextTick(() => {
                            this.doSearch(this.search)
                        })
                    }
                })
            }
        }
    },

    methods: {
        searchOptions(val) {
            this.search = val
            if (this.search) this.options = this.allOptions.filter((el) => el.name?.toLowerCase().includes(val?.toLowerCase()))
            else this.options = []
        },

        clearSearch() {
            this.search = null
            this.value = null
            this.options = []
            this.$emit('clear')
            this.$nextTick(() => {
                if (this.$route.name === 'search') this.$router.push({ name: 'index' })
            })
        },

        doSearch(val) {
            this.$router.push({
                name: 'search',
                query: {
                    q: val,
                },
            })
        },

        setSelect(val) {
            if (val?.name) this.doSearch(val.name)
        },
    },
}
