import { createPopper } from '@popperjs/core'

export default ({ app }, inject) => {
    inject('createPopper', (reference, popper) =>
        createPopper(reference, popper, {
            placement: 'bottom-end',
            strategy: 'fixed',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [8, 8],
                    },
                },
                {
                    name: 'preventOverflow',
                },
                {
                    name: 'flip',
                    options: {
                        padding: 25,
                        fallbackPlacements: ['top-start', 'top-end', 'bottom-start', 'bottom-end'],
                        allowedAutoPlacements: ['top-start', 'top-end', 'bottom-start', 'bottom-end'],
                    },
                },
            ],
        })
    )
}
