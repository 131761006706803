
export default {
    props: {
        user: {
            type: Object,
            default: () => {},
        },
        src: {
            type: String,
            default: undefined,
        },
        alt: {
            type: String,
            default: '',
        },

        size: {
            type: [Number, String],
            default: 32,
        },
        border: Boolean,
    },

    computed: {
        avatarSrc() {
            return this.user?.avatar?.url || this.user?.cover?.url || this.src || '/newuser.png'
        },
        imageAlt() {
            return this.user?.name || this.alt || 'User avatar'
        },
    },
}
