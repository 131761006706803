import { mapState, mapMutations } from 'vuex'
import tools from '@/mixins/tools'
import productName from '@/components/pages/payment/mixins/productName'
import loginMixin from '~/mixins/loginMixin'

export default {
    mixins: [loginMixin, tools, productName],

    data() {
        return {
            model: {
                email: null,
                password: null,
            },
        }
    },

    computed: {
        ...mapState({
            item: (state) => state.board.item || {},
            product: (state) => state.products.item || {},
            user: (state) => state.auth.user || {},
        }),

        isStyleQuiz() {
            return this.$route.name.includes('style-quiz')
        },
    },

    methods: {
        ...mapMutations({
            triggerBoardModal: 'board/triggerBoardModal',
        }),

        initApp(user) {
            this.$trackConnect()
            this.$trackEvent('LOGIN')
            // if (this.product?.id) return
            if (this.item.slug) {
                const onboarding = this.$cookies.get('onboarding') || {}
                onboarding.board = true
                this.$cookies.set('onboarding', onboarding)

                if (this.item.content_type.slug === 'wedding-inspiration') {
                    this.$store.dispatch('board/setItem', this.item).then(() => {
                        this.triggerBoardModal({})
                    })
                } else {
                    this.$store.dispatch('board/saveItem', this.item)
                }
            }
            if (this.$route.name.includes('tools-submit-wedding')) this.$router.push({ name: 'tools-submit-wedding-email' })
            else if (this.$route.name.includes('style-quiz')) {
                this.$router.push({ name: 'style-quiz-result' })
            } else if (this.$cookies.get('tool')) {
                this.navigateToTool(this.$cookies.get('tool'))
            } else if (user && !user.first_steps_at && (!this.item || !this.item.slug)) {
                this.$router.push({ name: 'complete-slug-step', params: { slug: user.is_vendor ? 'vendor-profile' : 'profile', step: 1 } })
            } else if (user && user.first_steps_at && (!this.item || !this.item.slug)) {
                this.$router.push({ name: 'dashboard' })
            } else if (user.is_vendor) {
                this.$router.push({ name: 'vendor-dashboard' })
            }

            const showMessagesModal = this.$cookies.get('open-messages-modal')
            if (showMessagesModal) {
                this.$cookies.set('open-messages-modal', false)
                this.$nuxt.$emit('open-messages-modal')
            }
        },
    },
}
