// import Vue from 'vue'

export const weddingChecklistActions = {
    'wedding-checklist-created'({ dispatch, rootState }, { model }) {
        if (!model.tool && !rootState.auth.user.checklist_setup_at)
            dispatch(
                'questionnaire/setModel',
                {
                    ...model,
                    tool: 'checklist',
                },
                { root: true }
            )
    },
    async 'wedding-checklist-save'({ dispatch }, model) {
        await dispatch('profile-save', model)
    },
}
