import Vue from 'vue'

export const profileActions = {
    'profile-created'({ dispatch, rootState }, { model }) {
        if (!model.set_first_steps && !rootState.auth.user.first_steps_at)
            dispatch(
                'questionnaire/setModel',
                {
                    ...model,
                    set_first_steps: true,
                },
                { root: true }
            )
    },
    async 'profile-save'({ dispatch, commit, rootState }, model) {
        await dispatch('questionnaire/generateModel', { user: rootState.auth.user }, { root: true })
        await dispatch('user/update', rootState.questionnaire.generatedModel, { root: true })
        Vue.options.methods.$trackEvent('QUESTIONNAIRE_COMPLETED', { page: model.slug, tag: model.tag })
        if (rootState.auth.user.sms_phone || rootState.auth.user.sms_optin) dispatch('klaviyo/event', 'idc_intent', { root: true })

        if (model.tag) commit('questionnaire/saveTag', model.tag)

        if (model.track) Vue.options.methods.$track(model.track, Vue.options.methods.$eventValues(rootState.auth.user, model.track_custom || {}))

        if (rootState.questionnaire.tag) {
            commit('questionnaire/saveTag', rootState.questionnaire.tag)
            commit('questionnaire/clearTag', undefined, { root: true })
        }

        if (model.klaviyo) {
            if (Array.isArray(model.klaviyo)) model.klaviyo.map((klaviyo) => dispatch('klaviyo/event', klaviyo, { root: true }))
            else dispatch('klaviyo/event', model.klaviyo, { root: true })
        }

        if (model.set_first_steps && !rootState.auth.user?.first_steps_at) {
            Vue.options.methods.$trackEvent('DONE_FIRST_STEPS')
            Vue.options.methods.$track('first steps done')
            dispatch('user/onboarding', undefined, { root: true })
        }

        if (model.tool && !rootState.auth.user[model.tool + '_setup_at']) {
            await dispatch('user/setTool', { type: model.tool }, { root: true })
            Vue.options.methods.$trackEvent(`DONE_${model.tool.toUpperCase()}_SETUP`)
        }
    },
}
