import Vue from 'vue'

export const vendorProfileActions = {
    'vendor-profile-done'({ dispatch, rootState }) {
        dispatch('user/update', { ...rootState.auth.user, is_vendor: true }, { root: true })
    },
    // 'vendor-profile-mounted': ({ rootState, commit }) => {
    //     const user = JSON.parse(JSON.stringify(rootState.auth.user))
    //     if (user.email && !user.vendor_details.email) {
    //         user.vendor_details.email = user.email
    //         commit('user/setUserData', user, { root: true })
    //     }
    // },
    async 'vendor-profile-created'(vuex, { model, route }) {
        const auth = JSON.parse(JSON.stringify(vuex.rootState.auth))
        console.log(auth)
        if (auth.user?.email && !auth.user?.vendor_details?.email) {
            if(!auth.user.vendor_details) auth.user.vendor_details = {}
            auth.user.vendor_details.email = auth.user.email
            vuex.commit('user/setUserData', auth.user, { root: true })
        }
        if (!vuex.rootState.questionnaire.isInited) {
            const generateModel = { user: auth.user, model }
            if (auth.loggedIn) {
                vuex.dispatch('questionnaire/generateFrontedModel', generateModel, { root: true })
            } else if (route.query.token || route.query.email) {
                setKlaviyoEvent(route.query.token ? 'loverly_planning_lead' : 'idc_lead')
                if (route.query.token) await loginCheck(vuex, generateModel, route)
                else await getToken(vuex, route, model)
                Vue.options.methods.$track('Register')
                Vue.options.methods.$trackEvent('REGISTER')
            }
        }

        if (route.query.klaviyo) setKlaviyoEvent(route.query.klaviyo)

        if (route.query.userfield)
            vuex.dispatch(
                'questionnaire/setModel',
                {
                    ...vuex.rootState.questionnaire.model,
                    [route.query.userfield]: route.query.value,
                },
                { root: true }
            )

        if (!model.set_first_steps && !auth.user.first_steps_at)
            vuex.dispatch(
                'questionnaire/setModel',
                {
                    ...model,
                    set_first_steps: true,
                },
                { root: true }
            )
    },
    async 'vendor-profile-save'({ dispatch }, model) {
        await dispatch('profile-save', model)
    },
}

function loginCheck(vuex, generateModel, route) {
    return this.$axios.$get(`/api/v1/auth/user-token/${route.query.token}`).then((res) => {
        vuex.commit('user/setUserData', res.data, { root: true })
        vuex.dispatch('questionnaire/generateFrontedModel', generateModel, { root: true }).then(() => {
            vuex.dispatch(
                'questionnaire/setModel',
                { ...vuex.rootState.questionnaire.model, token: route.query.token, email: vuex.rootState.auth.user.email },
                { root: true }
            )
        })
    })
}

function getToken(vuex, route, model) {
    return this.$axios
        .$post('/api/v1/external/idc_login', { email: route.query.email })
        .then((res) => {
            if (res && res.create_token) loginCheck(res.create_token)
        })
        .catch(() => {
            vuex.dispatch('questionnaire/generateFrontedModel', { user: {}, model }, { root: true }).then(() => {
                vuex.dispatch('questionnaire/setModel', { ...vuex.rootState.questionnaire.model, email: route.query.email }, { root: true })
            })
        })
}

function setKlaviyoEvent(event) {
    this.$cookies.set('klaviyoEvent', event)
}
