import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=2ab94c2c&lang=pug"
import script from "./Navigation.vue?vue&type=script&lang=js"
export * from "./Navigation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyV2Button: require('/app/components.v2/elements/Button.vue').default,LoverlyV2Icon: require('/app/components.v2/elements/Icon.vue').default,LoverlyV2Avatar: require('/app/components.v2/elements/Avatar.vue').default,LoverlyLogo: require('/app/components/elements/Logo.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyV2MenuVendor: require('/app/components.v2/menu/Vendor.vue').default,LoverlyV2MenuUser: require('/app/components.v2/menu/User.vue').default,LoverlyV2Collapse: require('/app/components.v2/elements/Collapse.vue').default})
