export default {
    props: {
        family: {
            type: String,
            default: 'fas',
        },
        icon: {
            type: String,
            default: 'question',
        },

        size: {
            type: [Number, String],
            default: 1,
        },
    },

    computed: {
        iconComponent() {
            const iconName = `${this.family}-${this.icon}`
            return this.icons[iconName] ? this.icons[iconName] : this.icons.default
        },

        iconUrl() {
            let url = 'https://loverly-static.storage.googleapis.com/fontawesome/svgs'
            switch(this.family) {
                case 'fal':
                    url += '/light'
                    break
                case 'far':
                    url += '/regular'
                    break
                case 'fab':
                    url += '/brands'
                    break
                default:
                    url += '/solid'
            }
            url += `/${this.icon}.svg`
            return url
        },

        iconStyle() {
            return this.size ? `width: ${this.size}rem; height: ${this.size}rem` : null
        },
    },

    data() {
        return {
            icons: {},
        }
    },
}
