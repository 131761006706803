
import { mapGetters } from 'vuex'
import dashboardMenu from '@/mixins/dashboardMenu'

export default {
    mixins: [dashboardMenu],

    computed: {
        ...mapGetters({ breakHeader: 'data/breakHeader' }),
    },
}
