import { eventActions } from './actions/event'
import { vendorProfileActions } from './actions/vendorProfile'
import { profileActions } from './actions/profile'
import { weddingChecklistActions } from './actions/weddingChecklist'
import { guestListActions } from './actions/guestList'

import { eventMutations } from './mutations/event'

import { eventStates } from './state/event'

export const state = () => ({
    ...eventStates,
})

export const mutations = {
    ...eventMutations,
}

export const actions = {
    ...eventActions,
    ...vendorProfileActions,
    ...profileActions,
    ...weddingChecklistActions,
    ...guestListActions,
}
