import Vue from 'vue'
import { set } from 'vue'

function mergeDeep(target, source) {
    const isObject = (obj) => obj && typeof obj === 'object'

    if (!isObject(target) || !isObject(source)) return source

    Object.keys(source).forEach((key) => {
        const targetValue = target[key]
        const sourceValue = source[key]

        if (Array.isArray(targetValue) && Array.isArray(sourceValue))
            target[key] = targetValue
                .concat(sourceValue)
                .filter(onlyUnique)
                .filter((el) => el)
        else if (isObject(targetValue) && isObject(sourceValue)) target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue)
        else target[key] = sourceValue
    })

    return target
}

function onlyUnique(value, index, array) {
    return array.indexOf(value) === index
}

export const state = () => ({
    model: {},
    generatedModel: {},
    isInited: false,
    tag: null,
})

export const mutations = {
    setModel(state, data) {
        set(state, 'model', data)
    },

    setTag(state, data) {
        set(
            state,
            'tag',
            state.tag
                ? Array.isArray(state.tag)
                    ? [...state.tag, data].filter((x, i, a) => a.indexOf(x) === i)
                    : [state.tag, data].filter((x, i, a) => a.indexOf(x) === i)
                : data
        )
    },

    clearTag(state, data = null) {
        if (data) {
            if (state.tag) {
                if (Array.isArray(state.tag)) {
                    const idx = state.tag.indexOf(data)
                    state.tag.splice(idx, 1)
                } else if (state.tag === data) set(state, 'tag', null)
            }
        } else {
            set(state, 'tag', null)
        }
    },

    saveTag({ commit }, tag) {
        if (Array.isArray(tag)) {
            const tags = tag

            if (tags?.length)
                this.$axios.$post('/api/v1/users/me/tag', { tag: tags[0] }).then(() => {
                    Vue.options.methods.$trackEvent('THIRD_PARTY_TAG', { tag: tags[0] })
                    tags.shift()
                    commit('questionnaire/saveTag', tags)
                })
        } else {
            Vue.options.methods.$trackEvent('THIRD_PARTY_TAG', { tag })
            this.$axios.$post('/api/v1/users/me/tag', { tag })
        }
    },

    generateModel(state, data) {
        if (data?.user?.id) {
            let user = { ...data.user, wedding_details: { ...data.user.wedding_details, style_quiz: null } }
            Object.keys(state.model)?.map((key) => {
                const pieces = key.split('.')

                const object = pieces.reverse().reduce((acc, el) => {
                    if (acc)
                        if (Array.isArray(acc)) acc = { [el]: acc.filter((accEl) => accEl).filter(onlyUnique) }
                        else acc = { [el]: acc }
                    else if (state.model[key] && Array.isArray(state.model[key])) acc = { [el]: state.model[key].filter((accEl) => accEl).filter(onlyUnique) }
                    else acc = { [el]: state.model[key] }

                    return acc
                }, null)

                user = mergeDeep(user, object)
            })
            set(state, 'generatedModel', user)
            set(state, 'isInited', false)
        }
    },

    generateFrontedModel(state, data) {
        const model = {}
        data.model?.pages?.map((page) => {
            page.fields?.map((field) => {
                if (field.userfield)
                    model[field.userfield] =
                        field.userfield.split('.').reduce((acc, el) => {
                            if (acc) {
                                if (Array.isArray(acc[el])) {
                                    acc = acc[el].filter(onlyUnique).filter((el) => el)
                                } else {
                                    acc = acc[el]
                                }
                            } else if (data.user && data.user[el]) {
                                if (Array.isArray(data.user[el])) {
                                    acc = data.user[el].filter(onlyUnique).filter((el) => el)
                                } else {
                                    acc = data.user[el]
                                }
                            } else {
                                acc = null
                            }

                            if (el === 'style_quiz') acc = []

                            return acc
                        }, null) || null
            })
        })

        set(state, 'model', model)
        set(state, 'isInited', true)
    },

    clear(state) {
        set(state, 'model', {})
        set(state, 'generatedModel', {})
        set(state, 'isInited', false)
        set(state, 'tag', null)
    },
}

export const actions = {
    setModel({ commit }, payload) {
        commit('setModel', payload)
    },

    generateModel({ commit }, payload) {
        commit('generateModel', payload)
    },

    generateFrontedModel({ commit }, payload) {
        commit('generateFrontedModel', payload)
    },

    clear({ commit }, payload) {
        commit('clear', payload)
    },
}
