export const state = () => ({})

export const mutations = {}

export const actions = {
    async delete({ rootState, dispatch }, {id,taxonomy_event_id} ) {
        await this.$axios.$delete(`/api/v1/guest_wedding_events/${id}`)
        const user = JSON.parse(JSON.stringify(rootState.auth.user))
        const weddingDetails = { ...user.wedding_details }
        const index = weddingDetails.events.indexOf(taxonomy_event_id)
        if (index !== -1) {
            weddingDetails.events.splice(index, 1)
            await dispatch('user/update', { ...user, ...{ wedding_details: weddingDetails } }, { root: true })
        }
    },
}
