// import Vue from 'vue'

export const guestListActions = {
    'guest-list-created'({ dispatch, rootState }, { model }) {
        if (!model.tool && !rootState.auth.user.guestlist_setup_at)
            dispatch(
                'questionnaire/setModel',
                {
                    ...model,
                    tool: 'guestlist',
                },
                { root: true }
            )
    },
    'guest-list-mounted'({ commit, rootState }, model) {
        if (!rootState.auth.user.wedding_details.events)
            commit('user/setUserData', { ...rootState.auth.user, wedding_details: { ...rootState.auth.user.wedding_details, events: [] } }, { root: true })
    },
    async 'guest-list-save'({ dispatch }, model) {
        await dispatch('profile-save', model)
    },
    'guest-list-done'({ rootState }) {
        // for (let i = 0; i < rootState.auth.user.wedding_details.events.length; i++) {
        //     const event = rootState.data.taxonomies.events.find((el) => {
        //         return el.id === rootState.auth.user.wedding_details.events[i]
        //     })
        //     if (event?.name) this.$axios.$post('/api/v1/guest_wedding_events', { name: event.name, taxonomy_event_id: event.id })
        // }
        rootState.auth.user.wedding_details.events.forEach((userEvent) => {
            const taxonomyEvent = rootState.data.taxonomies.events.find((el) => {
                return el.id === userEvent
            })
            if (taxonomyEvent?.name) this.$axios.$post('/api/v1/guest_wedding_events', { name: taxonomyEvent.name, taxonomy_event_id: taxonomyEvent.id })
        })
    },
}
