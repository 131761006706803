
import capitalize from '@/mixins/capitalize'
import { mapState } from 'vuex'
import auth from '@/mixins/forms/auth'

export default {
    mixins: [auth, capitalize],

    props: {
        inputClasses: {
            type: String,
            default: '',
        },

        noFlip: Boolean,

        modal: Boolean,
        isVendor: Boolean,
    },

    data() {
        return {
            loading: false,
        }
    },

    computed: {
        ...mapState({
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        subHeading() {
            if (this.isVendor) {
                return this.globalTexts?.registerVendor?.subtitle
            } else if (this.isStyleQuiz) {
                return 'Create an account to reveal your results.'
            } else if (this.product?.id) {
                return 'Create an account to complete your payment.'
            } else {
                return this.globalTexts.register.subtitle
            }
        },

        title() {
            if (this.isVendor) {
                return this.globalTexts?.registerVendor?.title
            } else {
                return this.globalTexts?.register?.title
            }
        },

        buttonLabel() {
            return this.isVendor ? this.globalTexts?.registerVendor?.button : this.globalTexts?.register?.button
        },
    },

    watch: {
        $route: {
            handler() {
                if (this.$route.query.email) this.model.email = this.$route.query.email
            },
            immediate: true,
        },
    },

    methods: {
        register() {
            if (this.$gtag && this.$gtag.event)
                this.$gtag.event('sign_up', { method: 'Google', event_category: 'user', event_label: 'modal', value: 0 })
            this.loading = true
            this.$store
                .dispatch('user/register', { ...this.model, is_vendor: this.isVendor })
                .then((response) => {
                    if (this.$route.query.email) {
                        const onboarding = this.$cookies.get('onboarding') || {}
                        onboarding.coreg = true
                        this.$cookies.set('onboarding', onboarding)
                    }

                    try {
                        this.$store.dispatch('klaviyo/event', 'loverly_organic_user')
                    } catch (e) {}

                    this.initApp(this.user)
                    this.$track('Register')
                    this.$trackEvent('REGISTER')
                })
                .catch(() => {})
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
