
import { mapState, mapGetters } from 'vuex'
import { parseISO, format, isValid } from 'date-fns'

export default {
    props: {
        isDd: Boolean,
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
        ...mapGetters({ breakHeader: 'data/breakHeader' }),

        vendor() {
            return this.user?.vendor || {}
        },

        service() {
            return this.vendor.taxonomy?.length ? this.vendor.taxonomy.find((tax) => tax.content_type?.slug === 'services') : {}
        },

        weddingDate() {
            return this.user.wedding_details?.date
                ? !isValid(this.user.wedding_details?.date)
                    ? format(parseISO(this.user.wedding_details?.date), 'dd MMM, yyyy')
                    : format(this.user.wedding_details?.date, 'dd MMM, yyyy')
                : null
        },
    },

    methods: {
        logout() {
            this.$auth.logout()
            this.$trackEvent('LOGOUT')
            this.$trackConnect()
        },

        async stopImpersonate() {
            const path = this.$cookies.get('impersonate_path') || '/'
            try {
                await this.$store.dispatch('user/stopImpersonate')
            } catch (q) {}
            if (path === this.$route.path) window.location.reload()
            else this.$router.replace(path)
        },
    },
}
