
import siteMixin from '@/mixins/siteMixin'
import { mapGetters } from 'vuex'

export default {
    name: 'UserLayout',

    middleware: ['auth'],

    mixins: [siteMixin],

    computed: {
        ...mapGetters({
            backgroundImage: 'layout/backgroundImage',
            background: 'layout/background',
         })
    },
}
