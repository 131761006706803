
import {  mapGetters } from 'vuex'
import dashboardMenu from '@/mixins/dashboardMenu'

export default {
    mixins: [dashboardMenu],

    computed: {
        ...mapGetters({ breakHeader: 'data/breakHeader' }),

        maxTextWidth() {
            return this.menu?.length ? `width: calc((100svw - ${8 * (this.menu.length + 1)}px) / ${this.menu.length})` : undefined
        },
    },
}
