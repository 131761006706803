export const state = () => ({
    title: null,
    to: null,
    background: 'bg-background',
    backgroundImage: undefined,
    menu: [],
})

export const mutations = {
    SET_TITLE(state, data) {
        state.title = data
    },
    SET_TO(state, data) {
        state.to = data
    },
    SET_BACKGROUND(state, data) {
        state.background = data || 'bg-background'
    },
    SET_BACKGROUND_IMAGE(state, data) {
        state.backgroundImage = data
    },
    SET_MENU(state, data) {
        state.menu = data || []
    },
}

export const actions = {
    setHeader({ commit }, payload) {
        commit('SET_TITLE', payload?.title)
        commit('SET_TO', payload?.to)
    },
    setBackground({ commit }, payload) {
        commit('SET_BACKGROUND', payload)
    },
    setBackgroundImage({ commit }, payload) {
        commit('SET_BACKGROUND_IMAGE', payload)
    },
    setMenu({ commit }, payload) {
        commit('SET_MENU', payload)
    },
}
export const getters = {
    title: (state) => state.title,
    to: (state) => state.to,
    background: (state) => state.background,
    backgroundImage: (state) => state.backgroundImage,
    menu: (state) => state.menu,
}
