
export default {
    /**
     * @property {string} level - 1 - 36px; 2 - 32px; 3 - 28px; 4 - 24px; 5 - 20px; 6 - 18px; 7 - 16px;
     * @property {string} size - 1 - 3xl ~ 30px; 2 - 2xl ~ 24px; 3 - xl ~ 20px; 4 - lg ~ 18px; 5 - base ~ 16px; 6 - sm ~ 14px;
     * @property {string} weight - thin - 100; extralight - 200; light - 300; normal - 400; medium - 500; semibold - 600; bold - 700; extrabold - 800; black - 900;
     * @property {string} color
     * @property {boolean} serif - font-juana
     * @property {boolean} center - text-center
     * @property {boolean} label - markap => 'label'
     * @property {boolean} flat - no margin
     */

    props: {
        level: {
            type: String,
            default: '1',
        },
        size: {
            type: String,
            default: undefined,
        },
        weight: {
            type: String,
            default: 'medium',
        },
        color: {
            type: String,
            default: 'text-general-5',
        },
        serif: Boolean,
        center: Boolean,
        label: Boolean,
        flat: Boolean,
        indicator: Boolean,
    },

    render(h) {
        return h(
            this.label ? 'label' : this.level > 7 ? `h${this.level}` : 'div',
            {
                class: [
                    this.label && !(this.size || this.level) ? 'text-12 tracking-wide inline-block' : `h${this.size || this.level}-v2`,
                    this.color,
                    this.serif ? 'font-v2-serif' : 'font-v2-sans',
                    `font-${this.weight}`,
                    { 'text-center': this.center, 'heading-flat': this.flat, 'heading-indicator': this.indicator },
                ],
            }, // TODO v2 - .h{level}-v2 => .h{level}
            this.$slots.default
        )
    },
}
